import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Python', 'PyTorch', 'TensorFlow', 'NumPy', 'LangChain', 'PyDICOM'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! My name is Luke. I am currently an undergraduate student at the University of Texas at Austin,
              studying biomedical engineering with a concentration on computation. My career passion lies in working on the 
              intersection of healthcare, artificial intelligence, and policy along with venture capital. Currently, my focus is on building products that leverage artificial intelligence to improve
              the field of medicine. 
              
            </p>

            <p>
              Throughout my young career, I have been blessed to do technical work at many places such as {' '}
              <a href="https://www.pfizer.com/">Pfizer</a>,{' '}
              <a href="https://www.mit.edu/">Massachusetts Institute Technology</a>,{' '}
              <a href="https://hms.harvard.edu/">Harvard Medical School</a>,{' '}
              <a href="https://www.merck.com/">Merck</a>, and{' '}
              <a href="https://www.utexas.edu/">UT Austin</a>. 
              
            </p>
            <p>
              
              I have also dabbled the field of venture capital and artificial intelligence entrepreneurship at {' '}
              <a href="https://www.plugandplaytechcenter.com/">Plug and Play</a>,{' '}
              <a href="https://1m1m.sramanamitra.com/">One Million by One Million</a>. Moreover, I was able to work with {' '}
              <a href="https://www.nyu.edu/">New York University</a> on studies about discrimination in healthcare. 
              
            
            </p>

            <p>
              Recently, I founded{' '}
              <a href="https://ainhealth.wixsite.com/home">
                a political movement
              </a>{' '}
              that advocates for the incentivization of artificial intelligence implementation into healthcare settings with a couple friends. 
              
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/headshot.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
